import React, { Fragment } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const ShareButton = () => {
  const handleClick = () => {
    navigator
      .share({
        title: document.title,
        text: 'The quiz game has been successfully completed! 🎉 Challenge yourself now and see who can get the highest score! Challenge yourself by playing now!',
        url: 'https://testquiz.nvsphr.fr/',
      })
      .then(() => console.log('Successfully shared'))
      .catch(error => console.log(error.message));
  };

  return (
    <Fragment>
  
    <span style={{ marginRight: '10px' }}></span>
    {/* Bouton de partage */}
    {navigator.share ? (
      <Button
        title="Share Now"
        size="big"
        circular
        icon="share alternate"
        onClick={handleClick}
        style={styles.shareButton}
      />
      ) : (
        <Modal
          closeIcon
          size="tiny"
          trigger={
            <Button
              title="Share"
              size="big"
              circular
              icon="share alternate"
              style={styles.shareButton}
            />
          }
        >
          <Modal.Header className="ui center aligned">Share on</Modal.Header>
          <Modal.Content className="ui center aligned container">
            <a
              href="https://www.facebook.com/essafiyassine.OFFICIEL/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="facebook" size="big" style={{ marginBottom: 8 }}>
                <Icon name="facebook" />
                Facebook
              </Button>
            </a>
            <a
              href="https://twitter.com/ESSAFI__Yassine"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="twitter" size="big" style={{ marginBottom: 8 }}>
                <Icon name="twitter" />
                Twitter
              </Button>
            </a>
            <a
              href="https://www.linkedin.com/in/essafi-yassine/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="linkedin" size="big">
                <Icon name="linkedin" />
                LinkedIn
              </Button>
            </a>
          </Modal.Content>
        </Modal>
      )}
    </Fragment>
  );
};

export default ShareButton;

const styles = {
  shareButton: {
    marginBottom: '10px', // Ajustez l'espacement entre le bouton de partage et les autres éléments
  },
};
