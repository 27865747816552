const CATEGORIES = [
  {
    key: '0',
    text: 'Any category',
    value: '0',
  },
  {
    key: 9,
    text: 'General cultural',
    value: 9,
  },
  {
    key: 10,
    text: 'Entertainment: books',
    value: 10,
  },
  {
    key: 11,
    text: 'Entertainment: Cinema',
    value: 11,
  },
  {
    key: 12,
    text: 'Entertainment: Music',
    value: 12,
  },
  {
    key: 13,
    text: 'Entertainment: musicals and theaters',
    value: 13,
  },
  {
    key: 14,
    text: 'Entertainment: Television',
    value: 14,
  },
  {
    key: 15,
    text: 'Entertainment: video games',
    value: 15,
  },
  {
    key: 16,
    text: 'Entertainment : Board games',
    value: 16,
  },
  {
    key: 17,
    text: 'Science & Nature',
    value: 17,
  },
  {
    key: 18,
    text: 'Science: Computers',
    value: 18,
  },
  {
    key: 19,
    text: 'Science: Mathematics',
    value: 19,
  },
  {
    key: 20,
    text: 'Mythology',
    value: 20,
  },
  {
    key: 21,
    text: 'Sports',
    value: 21,
  },
  {
    key: 22,
    text: 'Geography',
    value: 22,
  },
  {
    key: 23,
    text: 'History',
    value: 23,
  },
  {
    key: 24,
    text: 'Politics',
    value: 24,
  },
  {
    key: 25,
    text: 'Art',
    value: 25,
  },
  {
    key: 26,
    text: 'Celebrities',
    value: 26,
  },
  {
    key: 27,
    text: 'Animals',
    value: 27,
  },
  {
    key: 28,
    text: 'Vehicles',
    value: 28,
  },
  {
    key: 29,
    text: 'Entertainment: comics',
    value: 29,
  },
  {
    key: 30,
    text: 'Science: gadgets',
    value: 30,
  },
  {
    key: 31,
    text: 'Entertainment : Anime',
    value: 31,
  },
  {
    key: 32,
    text: 'Entertainment: Cartoon & Animations',
    value: 32,
  },
];

export default CATEGORIES;
