import React, { useState, useEffect } from 'react';
import { Menu, Button, Icon } from 'semantic-ui-react';
import Typical from 'react-typical';

const Header = () => {
  const [promptEvent, setPromptEvent] = useState(null);
  const [appInstalled, setAppInstalled] = useState(false);

  useEffect(() => {
    const checkIfAppInstalled = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setAppInstalled(true);
      }
    };
    checkIfAppInstalled();
    window.addEventListener('appinstalled', () => {
      setAppInstalled(true);
    });
  }, []);

  const handleDownload = () => {
    if (promptEvent) {
      promptEvent.prompt();
      promptEvent.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          setAppInstalled(true);
        }
        setPromptEvent(null);
      });
    }
  };

  return (
    <Menu stackable inverted>
    <Menu.Item header style={{ display: 'flex', justifyContent: 'center' }}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', margin: '0', fontFamily: 'Rakume, sans-serif', color: '#FFFFFF' }}>
      testQuiz
      </h1>
    </Menu.Item>
  </Menu>
  );
};
export default Header;
