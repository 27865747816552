const DIFFICULTY = [
  {
    key: '0',
    text: 'Any difficulty',
    value: '0',
  },
  {
    key: 'easy',
    text: 'Easy',
    value: 'easy',
  },
  {
    key: 'medium',
    text: 'Medium',
    value: 'medium',
  },
  {
    key: 'hard',
    text: 'Hard',
    value: 'hard',
  },
];

export default DIFFICULTY;
