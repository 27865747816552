const QUESTIONS_TYPE = [
  {
    key: '0',
    text: 'Any type',
    value: '0',
  },
  {
    key: 'multiple',
    text: 'Multiple choice',
    value: 'multiple',
  },
  {
    key: 'boolean',
    text: 'True / false',
    value: 'boolean',
  },
];

export default QUESTIONS_TYPE;
